import React, { useEffect, useState } from "react"
import "@fontsource/inter/latin.css"
import Layout from "../components/layout"
import FAQ from "../components/faq"
import MoreToCome from "../components/landing/more_to_come"
import LandingLoop from "../components/landing/landing_loop"
import PartnerMarquee from "../components/landing/partner_marquee"
import GeneralFooter from "../components/general_footer"
import { color } from "../components/defaults/styles/theme"
import InfoSection from "../components/landing/info"
import Introduction from "../components/landing/introduction"
import Expectations from "../components/landing/expectations"
import Speaker from "../components/landing/speaker"
import Showcars from "../components/landing/showcars"
import Speakerseries from "../components/landing/speakerseries"
import Sponsors from "../components/landing/sponsors"

const Open = () => {
  const [height, setHeight] = useState(null)

  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  return (
    <Layout footerBackground={color.white}>
      <LandingLoop windowHeight={height} />
      <InfoSection theme={color.main_light} />
      <Introduction theme={color.main_light} />
      <PartnerMarquee />
      <Speaker theme={color.main_light} />
      <Expectations theme={color.main_light} />
      {/* <Showcars theme={color.main_light} /> */}
      <Sponsors theme={color.main_light} />
      {/* <Speakerseries theme={color.main_light} /> */}
      <FAQ theme={color.main_light} />
      {/* <MoreToCome windowHeight={height} /> */}
      <GeneralFooter theme={color.main_light} />
    </Layout>
  )
}

export default Open
