import React from "react"
import { css } from "@emotion/react"
import mq from "../defaults/styles/mediaquerys"
import { color, fontStyle, spacing } from "../defaults/styles/theme"
import Link from "../link"
import Flags from "../../data/raw/flags"
import { wrapper } from "../defaults/styles/elements"
import { StaticImage } from "gatsby-plugin-image"
import Signature from "../defaults/assets/svg/signature.svg"

const Introduction = ({ theme }) => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${theme};
          overflow-x: clip;
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            display: flex;
            overflow-x: visible;
            position: relative;
            flex-direction: column;
            ${mq[1]} {
              flex-direction: row;
            }
          `,
        ]}
      >
        <div
          css={[
            css`
              z-index: 1;
              ${mq[1]} {
                max-width: 65%;
              }
              p {
                ${fontStyle.copy.landing}
              }
            `,
          ]}
        >
          <h2
            css={[
              fontStyle.header.landingSection,
              css`
                font-family: "PPNeueMachina";
              `,
            ]}
          >
            Welcome,
          </h2>
          <p>
            EXPO2023 will showcase over 30 pilot projects – from
            proof-of-concepts to implementations – jointly developed by selected
            startups and our corporate partners, leading companies in the fields
            of mobility, production, enterprise, sustainability, and beyond.
          </p>
          <p>
            The agenda is loaded with keynote speeches, panel discussions,
            roundtables, tech talks, project demos, and a dedicated matchmaking
            zone.
          </p>
          <p>
            Don’t miss your chance to witness the Plug and Play ecosystem in
            action — experience tangible results of open innovation, dive deeper
            into the latest tech trends, and network with more than 1,000
            entrepreneurs, investors, experts, and decision-makers in the cradle
            of the automobile – Stuttgart.
          </p>
          <p
            css={[
              css`
                margin-bottom: 0;
              `,
            ]}
          >
            Your,
          </p>
          <div
            css={[
              css`
                height: 2em;
                margin-top: -0.75em;
                margin-left: -0.5em;
                svg {
                  width: auto;
                  height: 100%;
                }
              `,
            ]}
          >
            <Signature />
          </div>
        </div>
        <div
          css={[
            css`
              display: flex;
              justify-content: flex-end;
              position: relative;
              /* filter: blur(5px); */
              margin-top: -80%;
              min-width: 650px;
              ${mq[0]} {
                margin-top: -60%;
              }
              ${mq[1]} {
                //overflow: hidden;
                position: absolute;
                bottom: 50%;
                transform: translate(0%, 50%);
                left: 40%;
                min-width: 900px;
              }
            `,
          ]}
        >
          <StaticImage
            object-fit="none"
            src="../../../static/img/expo2023visual-vert.png"
            style={{ position: "absolute" }}
            css={[
              css`
                filter: blur(100px);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              `,
            ]}
            alt="A dinosaur"
          />
          <StaticImage
            object-fit="none"
            src="../../../static/img/expo2023visual-vert.png"
            // src="../../../static/img/expo23visual.png"
            alt="A dinosaur"
          />
        </div>
      </div>
    </section>
  )
}

export default Introduction
